import "../../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import Axios from "axios";
import toast from 'react-hot-toast';
import ListJoinCourse from "./ListJoinCourse";
import {mainUrl} from "../../../common/common";

function JoinCourse() {

    const [list, setList] = React.useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const [needObject, setNeedObject] = React.useState({'project_id':0,'province_id':0,'branch_id':0,'class_id':0});
    const [searchParameter, setSearchParameter] = React.useState({
        'employee_id':'',
        'email':'',
        'project_id':'',
        'province_id':'',
        'branch_id':'',
        'position_id':''
    })
    const [searchStatus, setSearchStatus] = React.useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    function getJoinCourses() {
        return Axios.get(mainUrl + "getJoinCourse?page=1",{headers});
    }
    const loadData= async()=>{
        await Promise.all([getJoinCourses()])
            .then(function (res) {
                setList(res[0].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.joinCourse);
                } else {
                    // handle error
                }
            });;
    }


    const notifyError = (joinCourse) => toast.error(joinCourse, {
        duration: 6000,
    });
    const notifySuccess = (joinCourse) => toast.success(joinCourse, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">

                <div>
                      <ListJoinCourse
                          searchParameter={searchParameter}
                          setSearchParameter={setSearchParameter}
                          setNeedObject={setNeedObject}
                          needObject={needObject}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          showModal={showModal} setShowModal={setShowModal} setList={setList} list={list}  headers={headers} notifyError={notifyError}
                                 notifySuccess={notifySuccess}/>
                </div>

            </div>
        </>
    );


}


export default JoinCourse;
