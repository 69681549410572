import Dashboard from "views/Dashboard.js";
import Setups from "views/Setup/Setups";

import UserPage from "views/Profile/Profile.js";
import Reports from "views/Report/Reports.js";
import Message from "./views/Web/Message/Message";
import Home from "./views/Web/Page/Home/Home";
import Mentorship from "./views/Web/Page/Mentorship/Mentorship";
import Course from "./views/Web/Page/Courses/Course";
import Help from "./views/Web/Page/Help/Help";
import JoinCourse from "./views/Web/JoinCourse/JoinCourse";

const type = sessionStorage.getItem("type");

var dashboard= {
    path: "/dashboard",
        name: "dashboard",
    title:'',
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "",
};
var userProfile= {
    path: "/profile",
        name: "userProfile",
    title:'',
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "",
};

var joinCourse= {
    path: "/joinCourse",
    name: "joinCourse",
    title:'',
    icon: "nc-icon nc-single-02",
    component: JoinCourse,
    layout: "",
};
var setup= {
    path: "/Setup",
        name: "Setup",
    title:'',
    icon: "nc-icon nc-settings",
    component: Setups,
    layout: "",
};
var report= {
    path: "/Report",
    name: "Report",
    title:'',
    icon: "nc-icon nc-chart-bar-32",
    component: Reports,
    layout: "",
};
var home= {
    path: "/Home",
    name: "Home  Page",
    title:'',
    icon: "nc-icon nc-tv-2",
    component: Home,
    layout: "",
};
var strategy= {
    path: "/Mentorship",
    name: "Mentorship Page",
    title:'',
    icon: "nc-icon nc-layout-11",
    component: Mentorship,
    layout: "",
};

var course= {
    path: "/Course",
    name: "Course Page",
    title:'',
    icon: "nc-icon nc-hat-3",
    component: Course,
    layout: "",
};

var message= {
    path: "/Message",
    name: "Message",
    title:'',
    icon: "nc-icon nc-hat-3",
    component: Message,
    layout: "",
};

var information= {
    path: "/Help",
    name: "Help Page",
    title:'',
    icon: "nc-icon nc-layout-11",
    component: Help,
    layout: "",
};

var routes = [dashboard];
switch(type) {
    //Admin
    case "0dad138d82c8f678e4a5":
        routes.push(userProfile,message,joinCourse,report,setup,home,strategy,course, information);
        break;
    // Product
    // case "799a3091b121a0b3766f":
    //     routes.push(product);
    //     break;
    // //Order
    // case "5f86460849e109f281ea":
    //     routes.push(customer,order);
    //     break;
    // // productOrder
    // case "fb415b38d7ddcfda4771":
    //     routes.push(product,customer,order);
    //     break;
}
export default routes;
