
import React, {useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {
    Collapse,
    Navbar,
    Nav,
    NavItem,
    Container,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,Badge
} from "reactstrap";
import Axios from "axios"
import {mainUrl} from "../../common/common";
import {useTranslation} from "react-i18next";


function Header(props) {
    const history = useHistory();

    const { t, i18n } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const dropdownToggle = (e) => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <Navbar
            color={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "dark"
                    : "transparent"
            }
            expand="lg"
            className={
                props.location.pathname.indexOf("full-screen-maps") !== -1
                    ? "navbar-absolute fixed-top"
                    : "navbar-absolute fixed-top navbar-transparent "
            }
        >

            <Container fluid >
                <Collapse isOpen={false} navbar className="justify-content-start">
                    <Nav navbar>
                        <NavItem>
                            <p><span className="h5 fw-bold font-monospace text-info">
                                {/*{t('systemName')}*/}
                                Web content management system
                            </span></p>
                        </NavItem>
                    </Nav>
                </Collapse>
                <Collapse isOpen={false} navbar className="justify-content-end">
                    {/*<form >*/}
                    {/*    <InputGroup className="no-border" title="Search By User ID">*/}
                    {/*        <Input placeholder="Search  ..."  />*/}
                    {/*        <InputGroupAddon addonType="append">*/}
                    {/*            <InputGroupText>*/}
                    {/*                <span className="nc-icon nc-zoom-split pt-2"   />*/}
                    {/*            </InputGroupText>*/}
                    {/*        </InputGroupAddon>*/}
                    {/*    </InputGroup>*/}
                    {/*</form>*/}

                    <Nav navbar>
                        {/*<Dropdown  title="Select language" nav isOpen={dropdownOpen} toggle={(e) => dropdownToggle(e)}>*/}

                        {/*    <DropdownToggle caret nav>*/}
                        {/*        <i className="nc-icon nc-world-2"> </i>*/}
                        {/*        <span className="">*/}
                        {/*    <span className="badgelan">{*/}
                        {/*        sessionStorage.getItem('dir').substr(0,2)*/}
                        {/*    }</span>&nbsp;&nbsp;&nbsp;</span>*/}

                        {/*        <p>*/}
                        {/*            <span className="d-lg-none d-md-block">Language</span>*/}
                        {/*        </p>*/}
                        {/*    </DropdownToggle>*/}
                        {/*    <DropdownMenu   onClick={(e) => {*/}
                        {/*       const c=(e.target.attributes[0].value);*/}

                        {/*        sessionStorage.setItem('dir',c);*/}
                        {/*        document.location.reload();*/}
                        {/*    }}>*/}
                        {/*        <DropdownItem tag="a" value='english'>English</DropdownItem>*/}
                        {/*        <DropdownItem tag="a"  value='farsi'>Farsi (Dari)</DropdownItem>*/}
                        {/*        <DropdownItem tag="a"  value='pashto'>Pashto</DropdownItem>*/}
                        {/*    </DropdownMenu>*/}
                        {/*</Dropdown>*/}

                        <NavItem title="Logout">
                            <Link to="" className="nav-link btn-rotate">
                                <div onClick={() => {
                                    const headers = {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                                    }
                                    Axios.post(mainUrl + 'logout', {}, {headers}).then(res => {
                                        sessionStorage.clear();
                                        return history.push('/');
                                    });
                                }}>
                                    <i className="nc-icon nc-lock-circle-open"/>
                                    <p><span className="">Logout</span></p>
                                </div>
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
