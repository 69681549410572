// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Axios from "axios";
import {dataURLtoFile, mainUrl, toBase64} from "../../../../common/common";
import {Button, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner, Table} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast from "react-hot-toast";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";

export default function Course(props) {


    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [attachFiles, setAttachFiles] = useState("");
    const [downloadStatus, setDownloadStatus] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const [courseData, setCourseData] = React.useState({
        'id': '0',
        'title': '',
        'description': '',
        'detail': '',
        'photo': ''
    })

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.post(mainUrl + "getCourse", {'section_id': props.sectionId}, {headers});
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteCourse", {
            id: recordId
        }, {headers}).then(res => {
            notifySuccess("Successfully deleted");
            const lists = res.data;
            setList(lists);
        }).catch((error) => {
            notifyError(error.response.data.message);
        });
        setRecordId(0);
        setShowDeleteModal(false);
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <Modal show={props.showCourseModal} backdrop="static" size="xl" onHide={() => props.setShowCourseModal(false)}>

                <Modal.Header className="justify-content-center">
                    <div className="modal-profile text-info">
                        Course
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="content">
                        <div>
                            <AddItem/>
                            <TableData/>
                            <DeleteItem/>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        title="Close"
                        className="btn-simple"
                        type="button"
                        variant="link"
                        onClick={() => {
                            props.setShowCourseModal(false);
                        }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="md" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile text-gray">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 text-gray">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round btn-info" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem() {
        const [editorState, setEditorState] = useState(EditorState.createEmpty());
        function onEditorStateChange(editorState) {
            setEditorState(editorState);
        };

        useEffect(()=>{
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(courseData.detail))));
        },[]);

        const addCourse = async (e) => {
            e.preventDefault();
            const f = e.target;
            let attachFile = "";
            if (f.photo.files[0] != null && f.photo.files[0] != "") {
                attachFile = await toBase64(f.photo.files[0]);
            }
            await Axios.post(mainUrl + "addCourse", {
                id: e.target.ids.value,
                title: e.target.title.value,
                description: e.target.description.value,
                photo: attachFile,
                join_status: e.target.join_status.value,
                section_id: props.sectionId,
                detail:  draftToHtml(convertToRaw(editorState.getCurrentContent())),

            }, {headers}).then(res => {
                if (e.target.id.value == 0) {
                    notifySuccess(' Added Successfully.');
                } else {
                    notifySuccess(' Updated Successfully.');
                }
                setList(res.data);
                setShowModal(false);
            }).catch(function (error) {
                notifyError(error.response.data.message);
                setShowModal(false);
            });
        };
        return (
            <>

                <Modal show={showModal} backdrop="static" size="lg" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => {
                        document.getElementById("submit").disabled = true;
                        addCourse(e)
                    }}>
                        <div className="hideInput">
                            <Input type="text" className="hidden" required name="ids" id="ids"
                                   defaultValue={recordId}/>
                        </div>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile text-info">
                                Course
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{minHeight: "700px!important"}}>
                            <Row>
                                <Col className="p-3" md="12">
                                    <FormGroup>
                                        <label>Title : <span className="text text-danger">*</span></label>
                                        <input type="text" placeholder="Title Course" className="form-control"
                                               name="title" id="title" defaultValue={courseData.title}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-3" md="12">
                                    <FormGroup>
                                        <label>Description : <span className="text text-danger">*</span></label>
                                        <textarea className="form-control" id="description" name="description"
                                                  placeholder="description ..." defaultValue={courseData.description}></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-3" md="12">
                                    <FormGroup>
                                        <label>Join Status : <span className="text text-danger">*</span></label>
                                        <input className="form-check checkbox" id="join_status" name="join_status" type="checkbox"
                                               defaultChecked={courseData.join_status=='0'?true:false}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="text-info">
                                <Col className="p-3" md="12">
                                    <FormGroup>
                                        <label>Image <span className="text text-danger">*</span></label>
                                        <input name="photo" id='photo' accept=".jpg" type="file"
                                               title="Image"
                                               className="form-control"/>
                                        Select .JPG file only.<br/>
                                        Maximum upload file size :100kb<br/>
                                        (100 X 100) px <br/> Resize Image: <a href="https://www.resizepixel.com/"
                                                                              target="_blank">resizepixel.com</a>
                                    </FormGroup>
                                    {courseData.photo != "" &&
                                    <div>
                                        <img src={courseData.photo}    className=" p-3 border border-2"  style={{height: '130px', width: 'auto'}}/>
                                    </div>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col className="p-3" md="12">
                                    <label>Detail : </label>
                                    <div className="border border-1 border-dark text-info">
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName "
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                        <textarea className="hideInput" name="detail" id="detail"
                                                  defaultValue={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round btn-info" type="submit"
                                    id="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function TableData() {
        let no = 1;
        return (
            <>
                <Row>
                    <Col md="12">
                        <div className="Course-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h2> Course List </h2>
                                        </div>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-info" variant="primary"
                                                    onClick={() => {
                                                        setCourseData({
                                                            'id': '0',
                                                            'title': '',
                                                            'description': '',
                                                            'detail': '',
                                                            'photo': ''
                                                        })
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-info">
                                    <tr>
                                        <th>No</th>
                                        <th>Header</th>
                                        {/*<th>Description</th>*/}
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "100%"}}>
                                                {e.title}
                                            </td>
                                            {/*<td style={{width: "50%"}}>*/}
                                            {/*    <div dangerouslySetInnerHTML={{__html: e.description}}></div>*/}
                                            {/*</td>*/}
                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                     className='outline-info dropDownClass'>
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Edit"
                                                                onClick={async () => {
                                                                    setRecordId(e.id);
                                                                    setCourseData(e);

                                                                    await setShowModal(true);
                                                                }} className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button">
                                                            <span className="nc-icon nc-paper"/>&nbsp;&nbsp;
                                                            Edit
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                        >
                                                            <span className="nc-icon nc-simple-delete "/>&nbsp;&nbsp;
                                                            Delete
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
};
