import React from "react";
import {NavLink} from "react-router-dom";
import {Nav} from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import {Toaster} from "react-hot-toast";
import {useTranslation} from "react-i18next";

var ps;

function Sidebar(props) {

    const {t, i18n} = useTranslation();
    const sidebar = React.useRef();
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });
    return (
        <>
            <Toaster style={{ZIndex: "1000!important"}} position="top-right" reverseOrder={false}/>
            <div
                className="sidebar" style={{backgroundColor: "blue!important"}}
                data-color={props.bgColor}
                data-active-color={props.activeColor}>


                <div className="logo"
                     title='Made in Afghanistan , Admin panel'>
                    {/*<a href="https://www.creative-tim.com" className="simple-text logo-mini">*/}
                    <div className="text-center p-2 ">

                        <img src={sessionStorage.getItem("user_profile_image")}
                             width="150px" height="auto"
                             style={{
                                 borderRadius: "50%",
                                 backgroundColor: "#8080805e!important"
                             }}/>

                        {/*<img src={logo} alt="order-admin-logo" height="auto" width="auto" />*/}
                        <br/>
                        <span className="text-light text-sm-center">{sessionStorage.getItem("user_profile_name")}</span><br/>
                        <span
                            className="text-light text-sm-center">{sessionStorage.getItem("user_profile_email")}</span>
                    </div>

                </div>
                <div className="sidebar-wrapper" ref={sidebar}>
                    <Nav style={{paddingBottom:"250px"}}>
                        {props.routes.map((prop, key) => {
                            return (
                                <li
                                    className={
                                        activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                                    }
                                    key={key}
                                >
                                    <NavLink title={prop.title}
                                             to={prop.layout + prop.path}
                                             className="nav-link"
                                             activeClassName="active"
                                    >
                                        <i className={prop.icon}/>
                                        <p>
                                            {t(prop.name)}
                                            {/*{prop.name}*/}
                                        </p>
                                    </NavLink>
                                    {prop.name == 'Setup' &&
                                    <hr/>
                                    }
                                </li>
                            );
                        })}
                    </Nav>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
