import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Modal, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../../../common/common";


function AddHome(props) {
    const [cardStatus, setCardStatus] = useState(false);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    useEffect(()=>{
        // document.getElementById('section_sort').value = props.sectionData.section_sort;
    });

    const addHome = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "addSection", {
            id: f.id.value,
            pages: '1',
            section_name: f.section_name.value,
            section_sort: f.section_sort.value,
            section_type: f.section_type.value,
        }, {headers}).then(res => {
            props.setShowModal(false);
            props.setUpdateRecordStatus(false);
        }).catch(function (error) {
            props.notifyError(error);
        });
    };
    const changeType = (v) => {
        setCardStatus(v);
    }
    return (
        <div>
            <Modal show={props.showModal} backdrop="static" size="xl" onHide={() => props.setShowModal(false)}>
                <form onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }} onSubmit={(e) => {
                    document.getElementById("submit").disabled = true;
                    addHome(e)
                }}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile text-info">
                            Section
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="hideInput">
                            <Input type="text" className="hidden" required name="id" id="id"
                                   defaultValue={props.sectionData.id}/>
                        </div>
                        <div style={{height: '300px', marginTop: '20px', paddingBottom: '50px   '}}>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label className="pb-3">Section Name <span className="text text-danger">*</span></label>
                                        <Input required name="section_name" id="section_name"
                                               defaultValue={props.sectionData.section_name} placeholder="Section Name"
                                               title="Section Name" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label className="pb-3">Sort <span className="text text-danger">*</span></label>
                                        <select required name="section_sort" id='section_sort'
                                                defaultValue={props.sectionData.section_sort}
                                                className="form-control" title="Sort">
                                            <option value=""></option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => (
                                                <option key={e}
                                                        value={e}>{e}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <label className="pb-3">Type <span className="text text-danger">*</span></label>
                                        <select required name="section_type" id='section_type'
                                                defaultValue={props.sectionData.section_type}
                                                onChange={e => changeType(e.target.value)}
                                                className="form-control" title="Select Type of Section">
                                            <option value=""></option>
                                            <option value="3">card</option>
                                            <option value="1">Paragraph</option>
                                            <option value="2">Tab</option>
                                            <option value="4">Image</option>
                                            <option value="5">course</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {cardStatus == 3 && <div>
                                        Card :
                                        <div className="row">
                                            <div className="offset-2 col-md-6">
                                                <div className="row">
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center pt-3"
                                                        style={{height: "200px", backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            marginLeft: '20px',
                                                            height: '60px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '10px',
                                                            width: '60x',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '40px',
                                                            width: '100px',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                    </div>
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center pt-3"
                                                        style={{height: "200px", backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            marginLeft: '20px',
                                                            height: '60px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '12px',
                                                            width: '60x',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '42px',
                                                            width: '108px',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                    </div>
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center pt-3"
                                                        style={{height: "200px", backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            marginLeft: '20px',
                                                            height: '60px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '12px',
                                                            width: '60x',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '42px',
                                                            width: '108px',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {cardStatus == 1 && <div>
                                        Paragraph :
                                        <div className="row">
                                            <div className="offset-2 col-md-6">
                                                <div className="row">
                                                    <div className="m-1  col-md-9  pt-3"
                                                         style={{height: "200px", backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '10px',
                                                            width: '100px',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                        <br/>
                                                        <div className="" style={{
                                                            height: '130px',
                                                            width: 'auto',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {cardStatus == 2 && <div>
                                        Tab :
                                        <div className="row">
                                            <div className="offset-2 col-md-6">
                                                <div className="row">
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center p-2 "
                                                        style={{backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '10px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                    </div>
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center p-2 "
                                                        style={{backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '10px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                    </div>
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center p-2 "
                                                        style={{backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '10px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div
                                                        className="m-1 d-grid col-md-9  text-center justify-content-center pt-3"
                                                        style={{height: "200px", backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '60px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '10px',
                                                            width: '60px',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                        <div className="" style={{
                                                            height: '40px',
                                                            width: '200px',
                                                            backgroundColor: '#fff'
                                                        }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {cardStatus == 4 && <div>
                                        Image :
                                        <div className="row">
                                            <div className="offset-2 col-md-6">
                                                <div className="row">
                                                    <div
                                                        className="m-1 d-grid col-md-6  text-center justify-content-center p-2 "
                                                        style={{backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '100px',
                                                            width: '260px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                    </div>
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center p-2 "
                                                        style={{backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '100px',
                                                            width: '120px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {cardStatus == 5 && <div>
                                        Course :
                                        <div className="row">
                                            <div className="offset-2 col-md-6">
                                                <div className="row">
                                                    <div
                                                        className="m-1 d-grid col-md-3  text-center justify-content-center p-2 "
                                                        style={{backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '60px',
                                                            width: '60px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                        <br/>
                                                        <div className="" style={{
                                                            height: '100px',
                                                            width: '100px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                    </div>
                                                    <div
                                                        className="m-1 d-grid col-md-4  text-center justify-content-center p-2 "
                                                        style={{backgroundColor: "lightgray"}}>
                                                        <div className="" style={{
                                                            height: '100px',
                                                            width: '120px',
                                                            backgroundColor: '#eff'
                                                        }}></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    }

                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => {
                                props.setUpdateRecordStatus(false);
                                props.setShowModal(false)
                            }}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" id="submit"
                                type="submit">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default AddHome;
