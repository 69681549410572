export const UserTypeList = [
    {
        value: "1",
        label: "Admin"
    }
    // , {
    //     value: "2",
    //     label: "Product + Order"
    // }, {
    //     value: "3",
    //     label: "Product"
    // }, {
    //     value: "4",
    //     label: "Order"
    // }
];
