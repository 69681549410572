import React, {useEffect} from "react";
import Axios from "axios";
import {mainUrl} from "../../../../common/common";
import {Button, Col, Dropdown, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import Cards from "../../SectionType/Card/Cards";
import Course from "../../SectionType/Course/Course";
import Image from "../../SectionType/Image/Image";
import Paragraphs from "../../SectionType/Paragraph/Paragraphs";
import Tabs from "../../SectionType/Tab/Tabs";
import EditPage from "../EditPage";

function ListMentorship(props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [ristList, setRistList] = React.useState([]);

    const [sectionId, setSectionId] = React.useState(0);
    const [showCardModal, setShowCardModal] = React.useState(false);
    const [showCourseModal, setShowCourseModal] = React.useState(false);
    const [showImageModal, setShowImageModal] = React.useState(false);
    const [showParagraphModal, setShowParagraphModal] = React.useState(false);
    const [showTabModal, setShowTabModal] = React.useState(false);
    const [pageShowModal, setPageShowModal] = React.useState(false);

    const [pageNo, setPageNo] = React.useState(2);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        if (props.list.data != undefined) {
            setRistList(props.list.data)
        }
    });

    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteStrategy", {
            id: recordId
        }, {headers}).then(res => {
            props.notifySuccess("Successfully deleted");
            const lists = res.data;
            props.setList(lists);
        }).catch((error) => {
            props.notifyError(error.response.data.message);
        });

        setRecordId(0);
        setShowDeleteModal(false);
    }
    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchStrategy?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            } else {
                await Axios.get(mainUrl + "getStrategys?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.message);
                });
            }
        }

        setRecordId(0);
        setShowDeleteModal(false);
    }

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="lg" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile text-info">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 text-info">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round" color="danger" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    let rowNo = 1;
    return (
        <>
            <DeleteItem/>
            {showCardModal == true &&
            <Cards sectionId={sectionId} page="2" showCardModal={showCardModal} setShowCardModal={setShowCardModal}/>}
            {showCourseModal == true && <Course sectionId={sectionId} page="2" showCourseModal={showCourseModal}
                                                setShowCourseModal={setShowCourseModal}/>}
            {showImageModal == true && <Image sectionId={sectionId} page="2" showImageModal={showImageModal}
                                              setShowImageModal={setShowImageModal}/>}
            {showParagraphModal == true &&
            <Paragraphs sectionId={sectionId} page="2" showParagraphModal={showParagraphModal}
                        setShowParagraphModal={setShowParagraphModal}/>}
            {showTabModal == true &&
            <Tabs sectionId={sectionId} page="2" showTabModal={showTabModal} setShowTabModal={setShowTabModal}/>}
            {pageShowModal == true &&
            <EditPage  page="2" pageShowModal={pageShowModal} setPageShowModal={setPageShowModal}  notifyError={props.notifyError} notifySuccess={props.notifySuccess}/>}

            <div className="text-center content-center"
                 style={{position: 'absolute', marginLeft: '40%', marginTop: '15%'}}>
                <Spinner animation="border" variant="info"/>
            </div>
            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> Section List </h2>
                                    </div>
                                    <div className="ml-end">
                                        <Button title="Add Section" className="btn-info" variant=""
                                                onClick={() => {
                                                    setPageShowModal(true);
                                                }}>
                                            <span className="nc-icon nc-ruler-pencil"/> &nbsp; Edit Page
                                        </Button>
                                        <Button title="Add Section" className="btn-info" variant=""
                                                onClick={() => {
                                                    props.setShowModal(true);
                                                }}>
                                            <span className="nc-icon nc-simple-add"/> &nbsp; Section
                                        </Button>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>
                        {props.isLoading === false ? (
                            <div className="text-center">
                                <Spinner animation="border" variant="info"/>
                            </div>
                        ) : (
                            <CardBody>
                                <Table>
                                    <thead className="text-info">
                                    <tr>
                                        <th>No</th>
                                        <th>Section Name</th>
                                        <th>Sort</th>
                                        <th>Type</th>
                                        <th className="text-right ">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {ristList.map((e, index) => (
                                        <tr key={index}>
                                            <td>{rowNo++}</td>
                                            <td style={{width: "33.3%"}}>{e.section_name}</td>
                                            <td style={{width: "33.3%"}}>{e.section_sort}</td>
                                            <td style={{width: "33.3%"}}>
                                                {e.section_type == 1 && <span className="badge badge-success">Paragraph</span>}
                                                {e.section_type == 2 && <span className="badge badge-success">Tab</span>}
                                                {e.section_type == 3 && <span className="badge badge-success">Card</span>}
                                                {e.section_type == 4 && <span className="badge badge-success">Image</span>}
                                                {e.section_type == 5 && <span className="badge badge-success">Course</span>}
                                            </td>
                                            <td className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                     className='outline-info dropDownClass'>
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Edit"
                                                                style={{whiteSpace: "nowrap"}}
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                                onClick={  () => {
                                                                    props.setShowModal(true);
                                                                    props.setSectionData(e);
                                                                 }}>
                                                            Edit
                                                        </Button>
                                                        {e.section_type == 1 &&
                                                        <Button title="Delete Record"
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                                onClick={() => {
                                                                    setSectionId(e.id);
                                                                    setShowParagraphModal(true);
                                                                }}>
                                                            Paragraph
                                                        </Button>
                                                        }

                                                        {e.section_type == 2 &&
                                                        <Button title="Delete Record"
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                                onClick={() => {
                                                                    setSectionId(e.id);
                                                                    setShowTabModal(true);
                                                                }}>
                                                            Tab
                                                        </Button>
                                                        }

                                                        {e.section_type == 3 &&
                                                        <Button title="Delete Record"
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                                onClick={() => {
                                                                    setSectionId(e.id);
                                                                    setShowCardModal(true);
                                                                }}>
                                                            Card
                                                        </Button>
                                                        }

                                                        {e.section_type == 4 &&
                                                        <Button title="Delete Record"
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                                onClick={() => {
                                                                    setSectionId(e.id);
                                                                    setShowImageModal(true);
                                                                }}>
                                                            Image
                                                        </Button>
                                                        }

                                                        {e.section_type == 5 &&
                                                        <Button title="Delete Record"
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                                onClick={() => {
                                                                    setSectionId(e.id);
                                                                    setShowCourseModal(true);
                                                                }}>
                                                            Courses
                                                        </Button>
                                                        }

                                                        <Button title="Delete Record" style={{whiteSpace: 'nowrap'}}
                                                                className="btn-round dropdownlist dropDownClass"
                                                                variant="light"
                                                                type="Button"
                                                                onClick={() => {
                                                                    setRecordId(e.id);
                                                                    setShowDeleteModal(true);
                                                                }}>
                                                            Delete
                                                        </Button>

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                                <div className="page-content-center">
                                    {loadMore === true ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="info"/>
                                            </div>
                                        ) :
                                        (props.list.last_page >= pageNo ?
                                            <button className="btn  bg-light text-info" title="Load More"
                                                    onClick={(pageNumber => {
                                                        nextPageLoadList(pageNumber);
                                                    })}>
                                                <span className="nc-icon nc-simple-add text-info"/>
                                            </button>

                                            : "")}
                                </div>
                            </CardBody>
                        )
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListMentorship;
