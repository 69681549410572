import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Modal, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../../common/common";


function EditPage(props) {
    const [cardStatus, setCardStatus] = useState(false);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const [pageData, setPageData] = React.useState({
        'title': '',
        'description': ''
    })

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.post(mainUrl + "getWebPage", {'id': props.page}, {headers});
        const lists = data;
        setPageData(lists);
    };

    const editPage = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "editWebPage", {
            id: props.page,
            title: f.title.value,
            description: f.description.value
        }, {headers}).then(res => {
            props.setPageShowModal(false);
            props.notifySuccess("Successfully updated!");
        }).catch(function (error) {
            props.notifyError(error);
        });
    };

    return (
        <div>
             <Modal show={props.pageShowModal} backdrop="static" size="lg" onHide={() => props.setPageShowModal(false)}>
                <form onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }} onSubmit={(e) => {
                    document.getElementById("submit").disabled = true;
                    editPage(e);
                }}>
                    <Modal.Header className="justify-content-center">
                        <div className="modal-profile text-info">
                            Page
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="hideInput">
                            <Input type="text" className="hidden" required name="id" id="id" defaultValue={props.page}/>
                        </div>
                        <div style={{height: '300px', marginTop: '20px', paddingBottom: '50px   '}}>
                            <Row>
                                <Col  md="12">
                                    <FormGroup>
                                        <label className="pb-3">Title <span className="text text-danger">*</span></label>
                                        <Input required name="title" id="title"
                                               defaultValue={pageData.title} placeholder="Page Title"
                                               title="Page Title" type="text"/>
                                    </FormGroup>
                                </Col>

                                <Col  md="12">
                                    <FormGroup>
                                        <label className="pb-3">Description <span className="text text-danger">*</span></label>
                                        <textarea className="form-control" required name="description" id="description"
                                               defaultValue={pageData.description} placeholder="Description ..."
                                                  title="Description" ></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <Button
                            title="Close"
                            className="btn-simple"
                            type="button"
                            variant="link"
                            onClick={() => {
                                props.setPageShowModal(false)
                            }}>
                            Close
                        </Button>
                        <Button title="Insert & update  Record" className="btn-round" color="danger" id="submit" type="submit">
                            Submit
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default EditPage;
