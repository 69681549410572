import React, {useState} from "react";
import {CardBody, CardHeader, CardTitle} from "reactstrap";
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";

import WebSetting from "./WebSetting";

function Setups() {


    const [key, setKey] = useState('home');
    const [category, setCategory] = useState(false);
    const [subCategory, setSubCategory] = useState(false);
    const [currency, setCurrency] = useState(false);
    const [productType, setProductType] = useState(false);
    const [unitType, setUnitType] = useState(false);;
    const [slide, setSlide] = useState(false);
    const [webSetup, setWebSetup] = useState(true);
    const [other, setOther] = useState(false);
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const reSetClass=()=>{
        setSlide(false);
        setUnitType(false);
        setProductType(false);
        setCurrency(false);
        setSubCategory(false);
        setCategory(false);
        setWebSetup(false);
        setOther(false);
        document.getElementById('slide').classList.remove("tab-active");
        document.getElementById('unittype').classList.remove("tab-active");
        document.getElementById('producttype').classList.remove("tab-active");
        document.getElementById('currency').classList.remove("tab-active");
        document.getElementById('subcategory').classList.remove("tab-active");
        document.getElementById('category').classList.remove("tab-active");
        document.getElementById('webSetup').classList.remove("tab-active");
        document.getElementById('other').classList.remove("tab-active");
    }
    return (
        <>
            <div className="content">

                <Row>
                    <Col md="12">
                        <Card className=" ">
                            <CardHeader style={{backgroundColor: "#80808029"}}>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h3> Setup </h3>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>

                            <CardBody className="all-icons mr-4 ml-4">
                                <div>
                                    <div className="row col-md-12" style={{display: "inline-flex"}}>
                                        <div className="col-md-1 tab" id="webSetup" role="button" onClick={() => {
                                            // reSetClass();
                                            // document.getElementById('webSetup').classList.add("tab-active");
                                            // setWebSetup(true);
                                        }}>
                                            Web Setup
                                        </div>
                                        {/*<div className="col-md-1 tab" id="other" role="button" onClick={() => {*/}
                                        {/*    reSetClass();*/}
                                        {/*    document.getElementById('other').classList.add("tab-active");*/}
                                        {/*    setOther(true);*/}
                                        {/*}}>*/}
                                        {/*    Other*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="row">

                                        {webSetup==true && <WebSetting headers={headers}/>}
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default Setups;
