import "../../../../assets/css/bootstrap.min.css"
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Card, CardHeader, CardTitle} from "reactstrap";
import Axios from "axios";
import {mainUrl} from "../../../../common/common";
import toast from 'react-hot-toast';
import AddHome from "./AddHome";
import ListHome from "./ListHome";
import SearchHome from "./SearchHome";

function Home() {

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = React.useState([]);

    const [sectionData, setSectionData] = React.useState({
        'id': '0',
        'section_name': '',
        'section_sort': '',
        'section_type': ''
    })

    const [showModal, setShowModal] = useState(false);
    const [updateRecordStatus, setUpdateRecordStatus] = React.useState(false);
    const [needObject, setNeedObject] = React.useState({
        'project_id': 0,
        'province_id': 0,
        'branch_id': 0,
        'class_id': 0
    });
    const [searchParameter, setSearchParameter] = React.useState({
        'category_id': '',
        'sub_category_id': '',
        'home_name': ''
    })
    const [searchStatus, setSearchStatus] = React.useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }


    function getHomes() {
        return Axios.post(mainUrl + "getSection?page=1", {'pages':'1'},{headers});
    }

    const loadData = async () => {
        await Promise.all([getHomes()])
            .then(function (results) {

                setList(results[0].data);
                setIsLoading(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }


    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <div className="content">

                <div>
                    <Row>
                        <Col md="12">
                            <Card className="card-user">
                                <CardHeader style={{backgroundColor: "#80808029"}}>
                                    <CardTitle className="mr-4 ml-4">
                                        <div className="row">
                                            <div className="ml-right">
                                                <h3> Home </h3>
                                            </div>
                                        </div>
                                    </CardTitle>
                                </CardHeader>
                                {/*<SearchHome*/}
                                {/*    searchStatus={searchStatus} setSearchStatus={setSearchStatus}*/}
                                {/*    searchParameter={searchParameter} setSearchParameter={setSearchParameter}*/}
                                {/*    isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal}*/}
                                {/*    setShowModal={setShowModal} setList={setList}*/}
                                {/*    headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>*/}
                            </Card>
                        </Col>
                    </Row>
                    <AddHome sectionData={sectionData}
                        setNeedObject={setNeedObject} needObject={needObject}
                        setUpdateRecordStatus={setUpdateRecordStatus}
                        updateRecordStatus={updateRecordStatus}
                        isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal}
                        setShowModal={setShowModal} setList={setList} list={list}
                        headers={headers} notifyError={notifyError} notifySuccess={notifySuccess}/>

                    <ListHome setSectionData={setSectionData} searchStatus={searchStatus} setSearchStatus={setSearchStatus}
                              searchParameter={searchParameter}
                              setSearchParameter={setSearchParameter} setNeedObject={setNeedObject}
                              needObject={needObject}
                              setUpdateRecordStatus={setUpdateRecordStatus} updateRecordStatus={updateRecordStatus}
                              isLoading={isLoading} setIsLoading={setIsLoading} showModal={showModal}
                              setShowModal={setShowModal}
                              setList={setList} list={list} headers={headers} notifyError={notifyError}
                              notifySuccess={notifySuccess}/>
                </div>
            </div>
        </>
    );


}


export default Home;
