// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import Axios from "axios";
import {dataURLtoFile, mainUrl, toBase64} from "../../../../common/common";
import {Button, Col, Dropdown, Form, FormGroup, Modal, Row, Spinner,Table} from "react-bootstrap";
import {CardBody, CardHeader, CardTitle, Input} from "reactstrap";
import toast from "react-hot-toast";

export default function Image(props) {

    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [recordId, setRecordId] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
 
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    const [imageData, setImageData] = React.useState({
        'id': '0',
        'photo': '',
        'size': ''
    })

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        const {data} = await Axios.post(mainUrl + "getImage", {'section_id': props.sectionId}, {headers});
        const lists = data;
        setList(lists);
        setIsLoading(true);
    };
    const addCard = async (e) => {
        e.preventDefault();
        const f = e.target;
        let attachFile = "";
        if (f.photo.files[0] != null && f.photo.files[0] != "") {
            attachFile = await toBase64(f.photo.files[0]);
        }
        await Axios.post(mainUrl + "addImage", {
            id: e.target.ids.value,
            photo:  attachFile,
            title: e.target.title.value,
            section_id: props.sectionId,
            size: e.target.size.value
        }, {headers}).then(res => {
            if (e.target.id.value == 0) {
                notifySuccess(' Added Successfully.');
            } else {
                notifySuccess(' Updated Successfully.');
            }
            setList(res.data);
            setShowModal(false);
        }).catch(function (error) {
            notifyError(error.response.data.message);
            setShowModal(false);
        });
    };
    const onDeleteRecord = async (e) => {
        e.preventDefault();
        await Axios.post(mainUrl + "deleteImage", {
            id: recordId
        }, {headers}).then(res => {
            notifySuccess("Successfully deleted");
            const lists = res.data;
            setList(lists);
        }).catch((error) => {
            notifyError(error.response.data.message);
        });
        setRecordId(0);
        setShowDeleteModal(false);
    }

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    return (
        <>
            <Modal show={props.showImageModal} backdrop="static" size="xl" onHide={() => props.setShowImageModal(false)}>

                <Modal.Header className="justify-content-center">
                    <div className="modal-profile text-info">
                        Image
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="content">
                        <div>
                            <AddItem/>
                            <ImageleData/>
                            <DeleteItem/>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        title="Close"
                        className="btn-simple"
                        type="button"
                        variant="link"
                        onClick={() => {
                            props.setShowImageModal(false);
                        }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

    function DeleteItem() {
        return (
            <>
                <Modal show={showDeleteModal} backdrop="static" size="md" onHide={() => setShowDeleteModal(false)}>
                    <form onSubmit={(e) => onDeleteRecord(e)}>
                        <Modal.Header>
                            <div className="modal-profile text-gray">
                                <h2>Confirmation</h2>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="pr-3 text-gray">
                                <Col md="12">
                                    Are you sure you want to delete?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowDeleteModal(false)}>
                                Close
                            </Button>
                            <Button title="Yes, I am sure" className="btn-round btn-info" type="submit">
                                Yes
                            </Button>
                        </div>
                    </form>
                </Modal>
            </>
        );
    }

    function AddItem() {

        return (
            <>
                <Modal show={showModal} backdrop="static" size="lg" onHide={() => setShowModal(false)}>
                    <Form onSubmit={(e) => {
                        document.getElementById("submit").disabled = true;
                        addCard(e)
                    }}>
                        <div className="hideInput">
                            <Input type="text" className="hidden" required name="ids" id="ids"
                                   defaultValue={recordId}/>
                        </div>
                        <Modal.Header className="justify-content-center">
                            <div className="modal-profile text-info">
                                Image
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{minHeight: "700px!important"}}>
                            <Row>
                                <Col className="p-3" md="12">
                                    <FormGroup>
                                        <label>Image Title : <span className="text text-danger">*</span></label>
                                        <input type="text" placeholder="Image Title" className="form-control"
                                               name="title" id="title" defaultValue={imageData.title}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-3" md="12">
                                    <FormGroup>
                                        <label className="pb-3">Size <span className="text text-danger">*</span></label>
                                        <select required name="size" id='size'
                                                defaultValue={imageData.size}
                                                className="form-control" title="size">
                                            <option value=""></option>
                                            <option value="12">100 %</option>
                                            <option value="6">50 %</option>
                                            <option value="3">25 %</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="text-info">
                                <Col className="p-3" md="12">
                                    <FormGroup>
                                        <label>Image <span className="text text-danger">*</span></label>
                                        <input name="photo" id='photo' accept=".jpg" type="file"
                                               title="Image"
                                               className="form-control"/>
                                        Select .JPG file only.<br/>
                                        Maximum upload file size :100kb<br/>
                                        (100 X 100) px <br/> Resize Image: <a href="https://www.resizepixel.com/"
                                                                              target="_blank">resizepixel.com</a>
                                    </FormGroup>
                                    {imageData.photo != "" &&
                                    <div>
                                        <img src={imageData.photo}    className=" p-3 border border-2"  style={{height: '130px', width: 'auto'}}/>
                                    </div>
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div className="modal-footer">
                            <Button title="Close"
                                    className="btn-simple"
                                    type="button"
                                    variant="link"
                                    onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button title="Insert & update  Record" className="btn-round btn-info" type="submit"
                                    id="submit">
                                Add
                            </Button>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    function ImageleData() {
        let no = 1;
        return (
            <>
                <Row>
                    <Col md="12">
                        <div className="card-user">
                            <CardHeader>
                                <CardTitle className="mr-4 ml-4">
                                    <div className="row">
                                        <div className="ml-right">
                                            <h2> Image List </h2>
                                        </div>
                                        <div className="ml-end">
                                            <Button title="Add New Record" className="btn-info" variant="primary"
                                                    onClick={() => {
                                                        setImageData({
                                                            'id': '0',
                                                            'title': '',
                                                            'size': '',
                                                            'photo': ''
                                                        })
                                                        setShowModal(true)
                                                    }}>
                                                <span className="nc-icon nc-simple-add"/>
                                            </Button>
                                        </div>
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <thead className="text-info">
                                    <tr>
                                        <th>No</th>
                                        <th>Title</th>
                                        <th>Size</th>
                                        <th className="text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((e) => (
                                        <tr key={e.id}>
                                            <td>{no++}</td>
                                            <td style={{width: "50%"}}>
                                                {e.title}
                                            </td>
                                            <td style={{width: "50%"}}>
                                                {e.size==12  && <span>100 %</span>}
                                                {e.size==6  && <span>50 %</span>}
                                                {e.size==3  && <span>25 %</span>}
                                            </td>

                                            <td style={{whiteSpace: "nowrap"}} className="text-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic"
                                                                     className='outline-info dropDownClass'>
                                                        <span className="nc-icon nc-bullet-list-67"/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Button title="Edit"
                                                                onClick={async () => {
                                                                    setRecordId(e.id);
                                                                    setImageData(e);
                                                                    await setShowModal(true);
                                                                }} className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button">
                                                            <span className="nc-icon nc-paper"/>&nbsp;&nbsp;
                                                            Edit
                                                        </Button>
                                                        <br/>
                                                        <Button title="Delete Record" onClick={() => {
                                                            setRecordId(e.id);
                                                            setShowDeleteModal(true);
                                                        }} className="btn-round dropdownlist dropDownClass"
                                                                variant="light" type="Button"
                                                        >
                                                            <span className="nc-icon nc-simple-delete "/>&nbsp;&nbsp;
                                                            Delete
                                                        </Button>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
};
