import React, {useEffect, useState} from "react";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {Spinner} from "react-bootstrap"
import UNICEF from "./../assets/img/unhcr.jpg";
import WADAN from "./../assets/img/wadan.png";
import logoMAF from "./../assets/img/logoMAF.png";
import {useTranslation} from 'react-i18next';

import Axios from "axios";
import {mainUrl} from "common/common.js";
import PieChart from "./chart/PieChart";
import PieChartCustomerStatus from "./chart/PieChartCustomerStatus";
import PieChartOrderStatus from "./chart/PieChartOrderStatus";

function Dashboard() {
    const {t, i18n} = useTranslation();

    const [totalStudent, setTotalStudent] = useState(0);
    const [totalCourse, setTotalCourse] = useState(0);


    const [isLoading, setIsLoading] = useState(true);
    const [totalUsers, setTotalUsers] = useState(0);

    const [property, setProperty] = useState([]);
    const [title, setTitle] = useState("");
    const [dataChart, setDataChart] = useState([122, 12]);
    const [colorChart, setColorChart] = useState(["#ffc107", "#007bff"]);


    useEffect(() => {
        dashboardDetail();

        courseAnalisize();
    }, []);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }

    const dashboardDetail = async() => {
        await Axios.get(mainUrl + "dashboardStatus", {headers}).then(res => {

            setTotalStudent(res.data.totalStudent);
            setTotalCourse(res.data.totalCourse);
        });
    }

    const colorGenerator = (n) => {
        var color = [];
        for (let v = 0; v < n; v++) {
            var rgb = [];
            for (var i = 1; i < 4; i++)
                rgb.push(Math.floor(Math.random() * 255));
            color[v] = 'rgb(' + rgb.join(',') + ')';
        }
        return color;
    }

    const courseAnalisize = async () => {
        await Axios.get(mainUrl + "courseStatus", {headers}).then(res => {
            const value = [];
            const prop = [];
            let count = 0;
            res.data.map((e, index) => {
                count = count + e.total;
                value[index] = e.counts;
                prop[index] = e.name;
            });
            var color = colorGenerator(value.length);
            let pr = [];
            for (let v = 0; v < value.length; v++) {
                pr[v] = <div>  {color[v]} {prop[v]} {value[v]}</div>
            }

            setColorChart(color);
            setTotalUsers(count);
            // setTotalNewOrderOrder(value.length);
            setDataChart(value);
            setProperty(pr);
            setTitle("Course Status");
        });
    }

    return (
        <>
            <div className="content">
                {isLoading === false ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="info"/>
                    </div>
                ) : (
                    <div>
                        <Row>
                            <Col lg="6" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-user-run text-warning"/>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Course
                                                        {/*{t('totalAdminUser')}*/}
                                                    </p>
                                                    <CardTitle tag="p">{totalCourse}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        {/*<button className="btn btn-outline-warning"*/}
                                        {/*        style={{width: '100%', fontSize: '20px'}}*/}
                                        {/*>*/}
                                        {/*    User Status*/}
                                        {/*</button>*/}
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-app text-info"></i>
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">Total Student</p>
                                                    <CardTitle tag="p">{totalStudent}</CardTitle>
                                                    <p/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr/>
                                        {/*<button className="btn btn-outline-info"*/}
                                        {/*        style={{width: '100%', fontSize: '20px'}}>*/}
                                        {/*    Customer Status*/}
                                        {/*</button>*/}
                                    </CardFooter>
                                </Card>
                            </Col>

                        </Row>

                    </div>
                )}
                <Row>
                    <Col md={8}>
                        <PieChart colorChart={colorChart} dataChart={dataChart} property={property} title={title} totalUsers={totalUsers}/>
                    </Col>


                </Row>
            </div>
        </>
    );
}

export default Dashboard;
