import React, {forwardRef, useRef} from "react";
import {Card, CardHeader, CardTitle, Col, Row} from "reactstrap";
import ReactToPrint from "react-to-print";
import {useDownloadExcel} from "react-export-table-to-excel";

// import './Report.css';
function TableData(props) {

    const tableRef = useRef();
    const tableRefs = useRef();

    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRefs.current,
        filename: 'Report',
        sheet: 'Report'
    });

    const {onDownloads} = useDownloadExcel({
        currentTableRef: tableRefs.current,
        filename: 'Reports',
        sheet: 'Reports'
    });

    const ComponentToPrint2 = forwardRef((prop, reference) => (
        <div className=" card table table-reference p-4" key='1io9ooioii3' ref={reference}>
            <div key="123asd2" className="" ref={tableRefs}>

                <div>
                    <table className="table table-bordered" style={{verticalAlign: 'top'}}>
                        <thead className="text-primary">
                        <tr>
                            <td colSpan="8" className="text-primary content-center h3 p-5"
                                style={{textAlign: 'center'}}>
                                 Report
                            </td>
                        </tr>
                        <tr>
                            <th>No</th>
                            <th style={{width: "20%"}}>Name</th>
                            <th style={{width: "20%"}}>Last Name</th>
                            <th style={{width: "10%"}}>Email</th>
                            <th style={{width: "10%"}}>Phone</th>
                            <th style={{width: "20%"}}>Course</th>
                            <th style={{width: "10%"}}>Payment Status</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.list.map((e, index) => (
                            <tr key={index}>
                                <td>{index++}</td>
                                <td style={{width: "10%"}}>{e.name}</td>
                                <td style={{width: "10%"}}>{e.last_name}</td>
                                <td style={{width: "10%"}}>{e.email}</td>
                                <td style={{width: "10%"}}>{e.phone}</td>
                                <td style={{width: "10%"}}>{e.course_name}</td>
                                <td style={{width: "10%", textAlign: 'center', fontSize: '14pt'}}>
                                    {e.payment_status == 'true' &&
                                    <i className="nc-icon nc-check-2s text-success"> True
                                    </i>
                                    }
                                    {e.payment_status == 'false' &&
                                    <i className="nc-icon nc-simple-removes text-warning">
                                        False
                                    </i>}
                                </td>
                                <td style={{whiteSpace: 'nowrap'}}>{(e.created_at)}</td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    ));


    let componentRef = useRef();
    //
    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: 'A4'
        // format: [10,12]
    };
    // let rowNumber = 1;
    return (
        <>

            <Row>
                <Col md="12">
                    <Card className="card-user">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2> Result Report </h2>
                                    </div>
                                    <div className="ml-end">
                                        {/*<ReactToPdf targetRef={tableRef}  filename="div-blue.pdf" options={options} x={0} y={0} scale={0.75}>*/}
                                        {/*    {({toPdf}) => (*/}
                                        {/*        <button onClick={toPdf}>Generate pdf</button>*/}
                                        {/*    )}*/}
                                        {/*</ReactToPdf>*/}

                                        {(props.reportType == 0) &&
                                        <button className="btn btn-warning" onClick={onDownload}> Export excel</button>
                                        }

                                        {/*<button className="btn btn-warning" onClick={onDownload}> Export excel</button>*/}

                                        <ReactToPrint
                                            trigger={() => <button title="Print Report"
                                                                   className=" btn btn-dark">Print</button>}
                                            content={() => componentRef.current}
                                        />
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>


                        {(props.isLoading == 1 && props.reportType == 0) ?
                            <ComponentToPrint2 ref={componentRef}/> : ""
                        }

                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default TableData;
