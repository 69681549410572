import React, {useEffect, useState} from 'react';
import Axios from "axios"
import {useHistory} from 'react-router-dom';
import {mainUrl, webUrl} from "../../common/common";
import "./../../assets/css/bootstrap.min.css"
import "./Login.css"
import toast, {Toaster} from 'react-hot-toast';

// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import cms from "./../../assets/img/cargo.png";
import UNICEF from "./../../assets/img/unicepnglog.png";
import WADAN from "./../../assets/img/wadan.png";

function Login() {
    const [data, setData] = useState({
        email: "",
        password: ""
    })
    const [logo, setLogo] = useState("");
    const [loginStatus, setLoginStatus] = useState(false);
    useEffect(() => {
        sessionStorage.setItem('dir', "english");
        load();
    }, []);

    function handle(e) {
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const history = useHistory();

    function submit(e) {
        e.preventDefault();
        notifyLoading("Loading . . .");
        Axios.post(mainUrl + 'login', {
            email: data.email,
            password: data.password,
        }, {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Header': '*'
        }).then(res => {
            var elements = document.getElementsByClassName("go2072408551");
            elements[0].style.display = 'none';
            notifySuccess(res.data.message);

            sessionStorage.setItem("type", res.data.type);
            sessionStorage.setItem("dir", "English");
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("user_profile_image", res.data.image);
            sessionStorage.setItem("user_profile_name", res.data.name);
            sessionStorage.setItem("user_profile_email", res.data.email);

            setTimeout(() => {
                window.location.reload();
                // history.push('/dashboard');
            }, 1000);
            setLoginStatus(false);
        }).catch(function (error) {
            try {
                var elements = document.getElementsByClassName("go2072408551");
                elements[0].remove();
                notifyError(error.response.data.message)
            } catch (error) {
                notifyError("Error Network!");
            }
            setLoginStatus(false);
        });
    }

    const notifyError = (message) => toast.error(message);
    const notifySuccess = (message) => toast.success(message);
    const notifyLoading = (message) => toast.loading(message, {
        duration: 3000,
    });

    const forgotUrl = webUrl + "changePassword";

    const [passwordStatus, setPasswordStatus] = useState(false)
    const hideshowpassword = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
            setPasswordStatus(false);
        } else {
            x.type = "password";
            setPasswordStatus(true);
        }
    }

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*'
    }

    function getSetup() {
        return Axios.get(mainUrl + "getSetup", {headers});
    }

    const load = async () => {
        await Promise.all([getSetup()])
            .then(function (results) {
                setLogo(results[0].data.logo);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                } else {
                }
            });
        ;
    }

    return (
        <>
            <Toaster position="top-right" reverseOrder={true}/>
            <div className="container" style={{paddingTop: "5%"}}>
                <div className="row" style={{backgroundColor: '#fff', zIndex: '1001', padding: '20px'}}>
                    <div className="col align-self-start"></div>
                    <div className="col align-self-center" style={{boxShadow: '0px 0px 5px black', padding: '20px'}}>
                        <div className="Auth-form-title">
                            <img src={logo}
                                 style={{width: "auto", maxHeight: "150px", maxWidth: "200px", height: "auto"}}/><br/>
                            <h3 style={{color: '#000', whiteSpace: 'nowrap', paddingTop: '20px'}}>
                                {/*Made in Afghanistan Online Store Management system */}
                                Log in into Your Account
                            </h3>
                        </div>
                        <form className="Auth-form " style={{marginLeft: 'auto', marginRight: 'auto'}}
                              onSubmit={(e) => {
                                  setLoginStatus(true);
                                  submit(e);
                              }}>
                            <div className="Auth-form-content">

                                <br/>
                                <div className="form-group mt-3">
                                    <label>Email address</label>
                                    <input style={{borderRadius: '5px'}} onChange={(e) => handle(e)} id="email"
                                           value={data.email}
                                           type="email"
                                           className="form-control mt-1"
                                           title="Enter Your Email Address"
                                           placeholder="Enter Your Email Address"
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Password </label>
                                    <div className=""
                                         style={{display: 'flex', border: '1px solid gray', borderRadius: '5px'}}>
                                        <div style={{width: '100%'}}>
                                            <input style={{border: '0px solid'}} onChange={(e) => handle(e)}
                                                   id="password" value={data.password}
                                                   type="password"
                                                   className="form-control mt-1"
                                                   title="Enter your Account Password "
                                                   placeholder="Enter your Account Password"
                                            />
                                        </div>

                                        <div style={{padding: '10px'}} onClick={(e) => hideshowpassword()}
                                             role="button">
                                            {passwordStatus == true &&
                                            <i className="fa fa-eye"/>
                                            }
                                            {passwordStatus == false &&
                                            <i className="fa fa-eye-slash"/>
                                            }
                                        </div>
                                    </div>
                                    <div style={{float: 'right'}}>
                                        <p className="forgot-password text-center text-right ">
                                            <a className="" title="forgot Password" style={{color: '#FF6E40FF'}}
                                               target="_blank"
                                               href={forgotUrl}>Forgot Password</a>
                                        </p>
                                        {/*&nbsp;&nbsp; Show Password*/}
                                    </div>
                                    <br/><br/>
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn "
                                            style={{backgroundColor: '#0e7900', color: '#fff'}}
                                            title="Submit">
                                        {
                                            loginStatus == false &&
                                            <span>
                                              <i className="fa fa-key"/>  &nbsp;&nbsp; Login Securely
                                            </span>
                                        }
                                        {
                                            loginStatus == true &&
                                            <span>
                                               PLEASE WAIT...
                                            </span>
                                        }
                                    </button><br/><br/>
                                    <a href='https://newfold.com/privacycenter/addendumfor-california-users'
                                       className="btn " target='_blank'>
                                        DO NOT SELL MY PERSONAL INFORMATION
                                    </a>
                                    {/*<br/>*/}
                                    <span style={{fontSize: '8pt', textAlign: 'center'}}>
                                       Copyright ©️ {new Date().getFullYear()} GRU All right reserved. Developed By: Aria Delta Consulting Group | Aclick| Aria Delta TT
                                    </span>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div className="col align-self-end"></div>
                </div>
            </div>
            <div className="row " id="footers" style={{marginTop: '100px', display: 'none', zIndex: '-1'}}>
                {/*<div className="row " id="footers" style={{marginTop: '100px', display: 'block!important', zIndex: '-1'}}>*/}
                <div>

                    <div className="col align-self-start">
                    </div>
                    <div className="col align-self-center ">
                        <a target="_blank" href="https://www.unicef.org"> <img src={UNICEF} alt="Unicef " height="30"
                                                                               width="auto"/></a> &nbsp;&nbsp;&nbsp;&nbsp;
                        {/*<a target="_blank" href="https://ariadelta.af/"><img src={ARIADELTA} alt="Aria Delta" height="40" width="auto" /></a>&nbsp;&nbsp;&nbsp;&nbsp;*/}
                        <a target="_blank" href="https://www.wadan.org"><img src={WADAN} alt="Wadan " height="40"
                                                                             width="auto"/></a>

                        <div className="copyright p-3  " style={{fontSize: "8pt"}}>
                            {/*Copyright &copy; {2022}  by Aria Delta Consulting Group<br/>*/}
                            {/*All rights reserved.*/}
                            Copyright &copy; {2022} by WADAN<br/>
                            All rights reserved.
                        </div>
                    </div>

                    <div className="col align-self-end">
                    </div>

                </div>

            </div>
        </>
    );
}

export default Login;
