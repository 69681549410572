import React, {useEffect} from "react";
import Axios from "axios";
import {Button, Col, Dropdown, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {mainUrl} from "../../../common/common";

// import Pagination from "@material-ui/lab/Pagination";
// import Dropdown from "bootstrap/js/src/dropdown";

function ListJoinCourse(props) {

    const [recordId, setRecordId] = React.useState(0);
    const [loadMore, setLoadMore] = React.useState(false);
    const [recordList, setRecordList] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(2);
    const [showViewModal, setShowViewModal] = React.useState(false);
    const [mainRecord, setMainRecord] = React.useState({});

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
    useEffect(() => {
        setRecordList(props.list.data);
    })

    const nextPageLoadList = async (pageNumber = 1) => {
        // e.preventDefault();
        if (props.list.last_page >= pageNo && loadMore == false) {
            setLoadMore(true);
            setPageNo(1 + pageNo);
            if (props.searchStatus === true) {
                await Axios.post(mainUrl + "searchJoinCourse?page=" + pageNo, props.searchParameter, {headers}).then(res => {
                    const lists = res.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.joinCourse);
                });
            } else {
                await Axios.get(mainUrl + "getJoinCourse?page=" + pageNo, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        props.list.data.push(e);
                    })
                    lists.data = props.list.data;
                    props.setList(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    props.notifyError(error.response.data.joinCourse);
                });
            }
        }

        setRecordId(0);
    }


    let rowNo = 1;
    return (
        <>
            <Row>

                <Col md="12">
                    <Card className="card-">
                        <CardHeader>
                            <CardTitle className="mr-4 ml-4">
                                <div className="row">
                                    <div className="ml-right">
                                        <h2>Join </h2>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardHeader>

                        <CardBody>
                            <table className="table table-bordered table-responsive">
                                <thead className="text-info">
                                <tr>
                                    <th>No</th>
                                    <th style={{width: "10%"}}>Name</th>
                                    <th style={{width: "10%"}}>Last Name</th>
                                    <th style={{width: "10%"}}>Email</th>
                                    <th style={{width: "10%"}}>Phone</th>
                                    <th style={{width: "10%"}}>Course</th>
                                    <th style={{width: "10%"}}>Payment Status</th>
                                    <th>Date </th>
                                    {/*<th className="text-right">Action</th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                {props.isLoading === false ? (
                                    <tr>
                                        <td colSpan='5' className="text-center">
                                            <Spinner animation="border" variant="info"/>
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {recordList.map((e, index) => (
                                            <tr key={index}>
                                                <td>{rowNo++}</td>
                                                <td style={{width: "10%"}}>{e.name}</td>
                                                <td style={{width: "10%"}}>{e.last_name}</td>
                                                <td style={{width: "10%"}}>{e.email}</td>
                                                <td style={{width: "10%"}}>{e.phone}</td>
                                                <td style={{width: "10%"}}>{e.course_name}</td>
                                                <td style={{width: "10%",textAlign:'center',fontSize:'14pt'}}>
                                                    {e.payment_status=='true' &&  <i className="nc-icon nc-check-2 text-success"/>}
                                                    {e.payment_status=='false' &&  <i className="nc-icon nc-simple-remove text-warning"/>}
                                                </td>
                                                <td style={{whiteSpace:'nowrap'}}>{e.created_at}</td>

                                            </tr>
                                        ))
                                        }
                                    </>
                                )
                                }
                                </tbody>
                            </table>
                            <div className="page-content-center">
                                {loadMore === true ? (
                                        <div className="text-center">
                                            <Spinner animation="border" variant="info"/>
                                        </div>
                                    ) :
                                    (props.list.last_page >= pageNo ?
                                        <button className="btn  bg-light text-info" title="Load More"
                                                onClick={(pageNumber => {
                                                    nextPageLoadList(pageNumber);
                                                })}>
                                            <span className="nc-icon nc-simple-add text-info"/>
                                        </button>

                                        : "")}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default ListJoinCourse;
